import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import {StorageService} from "../core/services/storage.service";
import {formatDate} from "@angular/common";
import {AuthenticationService} from "../core/services/authentication.service";
import {Token, TokenFE} from "../models/FeCredentialsModel";
import {CLModalType, ModalService} from "@clavisco/alerts";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  private apiUrls = {
    'ApiAppUrl': environment.ApiAppUrl,
    'ApiFEUrl': environment.ApiFEUrl,
    'ApiCabysURL': environment.ApiCabysURL
  };
  constructor(
    private storage: StorageService,
    private authenticationService: AuthenticationService,
    private modalService : ModalService
  ) {}

  /**
   * Intercepta todas las solicitudes HTTP salientes y modifica dinámicamente la URL base
   * según la API a la que se dirige la solicitud.
   * @param request La solicitud HTTP saliente que se está interceptando.
   * @param next El siguiente manipulador de la cadena de interceptores en la cola.
   * @returns Un observable que emite eventos HTTP resultantes de la solicitud modificada.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('assets')) {
      return next.handle(request);
    }
    let clonedRequest: HttpRequest<unknown> = request.clone();
    if (clonedRequest.headers.has('API')) {
      const api = clonedRequest.headers.get('API');
      const apiUrl = this.apiUrls[api as keyof typeof this.apiUrls];
      if (apiUrl && !clonedRequest.url.includes(apiUrl)) {
        clonedRequest = clonedRequest.clone({url: `${apiUrl}${clonedRequest.url}`});
      }

      //Headers
      if(this.storage.GetCurrentSession()) {
        let userToken = this.storage.GetItem<Token>('currentUser');
        let userFEToken = this.storage.GetCurrentFESession();

        if (api === 'ApiAppUrl') {
          clonedRequest = this.AddAuthentication(clonedRequest);
        } else if (api === 'ApiFEUrl') {
          clonedRequest = this.AddFEAuthentication(clonedRequest);
        }
        let headers = clonedRequest.headers
          .set('cl-ui-request-timestamp', formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss.SSS a', 'en'));

        clonedRequest = clonedRequest.clone({headers});

        // Valido que no tenga el header que indica si tiene archivos para no enviar el content type
        if (!clonedRequest.headers.has('Request-With-Files') && api != 'ApiFEUrl') {
          let headers = clonedRequest.headers.set('Content-Type', 'application/json');
          clonedRequest = clonedRequest.clone({headers});
        }
      }
    }

    return next.handle(clonedRequest);
  }

  /**
   * Adds the authentication token to the HTTP request if the user is authenticated.
   *
   * @param {HttpRequest<any>} request - The original HTTP request.
   * @returns {HttpRequest<any>} - The HTTP request with the authentication header added, if the user is authenticated.
   *
   * This method first retrieves the token from the current session storage. If there is no token, it returns the original request.
   * If the user is not authenticated, it logs the user out and throws an error. Otherwise, it adds the authentication header
   * with the token to the request and returns the modified request.
   */
  private AddAuthentication (request: HttpRequest<any>): HttpRequest<any> {
    const TOKEN = this.storage.GetItem<Token>('currentUser')!;
    if (!TOKEN) {
      return request;
    }
    if (!this.authenticationService.IsAuthenticated()) {
      this.authenticationService.logout();
    }else{
      let headers: HttpHeaders = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);
      request = request.clone({headers});
    }

    return request;
  }

  /**
   * Adds Front-End (FE) authentication to an HTTP request by including an authorization token.
   *
   * @param request - The original HTTP request that may require FE authentication.
   * @returns The modified HTTP request with an added Authorization header if a valid token is available;
   *          otherwise, the original request is returned unchanged.
   */
  private AddFEAuthentication (request: HttpRequest<any>): HttpRequest<any> {

    const TOKEN: TokenFE = this.storage.GetCurrentFESession();
    if (!TOKEN) {
      return request;
    }
    if (!this.authenticationService.IsFEAuthenticated()) {
      this.authenticationService.logout();
    }else{
      let headers: HttpHeaders = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);
      request = request.clone({headers});
    }

    return request;
  }
}
